<template>
 <div>
  <div v-if="!getProduct.name"><Spinner/></div>
   <div v-else class="container client-container" >
     <div class="show-path"><img class="path-img" src="../../../assets/clients/path-img.svg" style="margin:1px 5px 0 0;"><div @click="$router.go(-1)"  class="mr-1">Back </div> | <span > {{getProduct.name}}</span> </div>
     <div class="row  mb-5" >
       <div class="col-lg-10  m-auto">
         <div class="row">
           <div class="col-lg-7 detail-right">

             <div class="product-img" :class="{active: getProduct.imgArray.length===0}" id="container">
               <!--            <img :src="server+'/'+getProduct.img">-->
               <img v-if="!getProduct.error" :src="server+'/'+getProduct.img" @error="getProduct.error=true">
               <img v-else src="../../../assets/icons/noProd.png" >
               <img v-for="(img,index) in getProduct.imgArray" :key="index" :src="server+'/'+img">
             </div>
             <div v-if="getProduct.imgArray.length>0" class="multiple-items">
               <div class="slider-item">
                 <img v-if="!getProduct.error" :src="server+'/'+getProduct.img" @error="getProduct.error=true">
               </div>
               <div v-for="(img,index) in getProduct.imgArray" :key="index" class="slider-item">
                 <img :src="server+'/'+img">
               </div>
             </div>
           </div>
           <div class="col-lg-5 detail-left" :class="{active: getProduct.imgArray.length===0}">
             <h3 class="product-name">{{getProduct.name}}</h3>
             <h5 class="product-code">{{getProduct.code}}</h5>

             <!-- <div v-if="getProduct.hasMultipleTypes">
               <div>
                 <span>Sizes</span>
               </div>

               <div style="display: flex;flex-wrap: wrap;">
                 <div @click="selectSize(size.size)" v-bind:class="{ selectedSizeClass: selectedSize ===size }"   class="sizeItemClass" v-for="(size, index) in getProduct.sizes.filter(prop => prop.quantity > 0)" :key="index">
                   {{ size.size }}
                 </div>
               </div>



              <select class="form-select" aria-label="Size selector"  @change="selectSize($event)" >
                <option selected>Select size</option>
                <option v-for="(size, index) in getProduct.sizes.filter(prop => prop.quantity > 0)" :key="index" v-bind:value="size.size">{{ size.size }}</option>
              </select>
             </div> -->

             <div v-if="getProduct.hasMultipleTypes">
               <h1 v-if="checkDates(getProduct.promoStart,getProduct.promoEnd)" class="product-price">{{getProduct.promoPrice}} {{catalog_settings.currency}}</h1>

               <h3 :class="{lineThrough:checkDates(getProduct.promoStart,getProduct.promoEnd)}" class="product-price">{{selectedSize.price || getProduct.price}} {{catalog_settings.currency}}</h3>
               <br>
             </div>

             <div v-else>
               <h1 v-if="checkDates(getProduct.promoStart,getProduct.promoEnd)" class="product-price">{{getProduct.promoPrice}} {{catalog_settings.currency}}</h1>
               <h3 :class="{lineThrough:checkDates(getProduct.promoStart,getProduct.promoEnd)}" class="product-price">{{getProduct.price}} {{catalog_settings.currency}}</h3>
               <br>
             </div>

             <div v-if="getProduct.productCustomField1 && getProduct.productCustomField1 !== 'undefined'">
              {{ getProduct.productCustomField1 }}
              <br>
            </div>
            <div v-if="getProduct.productCustomField2 && getProduct.productCustomField2 !== 'undefined'">
              {{ getProduct.productCustomField2 }}
              <br>
            </div>
            <br>

             

            <div v-if="getProduct.description && getProduct.description !== ''">
              <h3 class="price mt-0">Description</h3>
              <p class="product-text">{{getProduct.description}}</p>
            </div>

             <div class="container-box">

              <div class="size-main" v-if="getProduct.hasMultipleTypes">
                <div class="size" @click="open_sizes = !open_sizes">
                  <div class="text">Select size</div>
  
                  <div class="arrow">
                    <div v-if="open_sizes">
                      <svg width="19" height="20" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M4.24106 8.07244C4.53326 7.77438 4.99051 7.74728 5.31272 7.99115L5.40503 8.07244L12 14.8L18.595 8.07244C18.8872 7.77438 19.3444 7.74728 19.6666 7.99115L19.7589 8.07244C20.0511 8.3705 20.0777 8.83691 19.8386 9.16558L19.7589 9.25974L12.582 16.5806C12.2898 16.8787 11.8325 16.9058 11.5103 16.6619L11.418 16.5806L4.24106 9.25974C3.91965 8.93188 3.91965 8.4003 4.24106 8.07244Z" fill="#200E32"/>
                      </svg>
                    </div>
                    <div v-else>
                      <svg width="19" height="20" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M19.7589 17.1232C19.4667 17.4213 19.0095 17.4484 18.6873 17.2045L18.595 17.1232L12 10.3957L5.40503 17.1232C5.11283 17.4213 4.65558 17.4484 4.33337 17.2045L4.24106 17.1232C3.94886 16.8252 3.9223 16.3588 4.16137 16.0301L4.24106 15.9359L11.418 8.61504C11.7102 8.31698 12.1675 8.28988 12.4897 8.53375L12.582 8.61504L19.7589 15.9359C20.0804 16.2638 20.0804 16.7954 19.7589 17.1232Z" fill="#200E32"/>
                      </svg>
                    </div>
                  </div>
                </div>
                
  
                <div class="size-box" v-if="open_sizes">
                  <div v-for="(size, index) in getProduct.sizes" :key="index" class="size-option">
                      <div class="size-name">
                          <input 
                              style="appearance:checkbox;" 
                              type="radio" 
                              :id="'size' + index" 
                              :value="size" 
                              v-model="picked_size" 
                          />
                          <span>{{ size.size }}</span>
                      </div>
                      <div class="size-price">
                          {{ size.price }} {{catalog_settings.currency}}
                      </div>
                  </div>
                </div>
              </div>
  
              <!---->
  
              <div class="size-main" v-if="getProduct.colors.length > 0">
                <div class="size" @click="open_colors = !open_colors">
                  <div class="text">Select color</div>
  
                  <div class="arrow">
                    <div v-if="open_colors">
                      <svg width="19" height="20" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M4.24106 8.07244C4.53326 7.77438 4.99051 7.74728 5.31272 7.99115L5.40503 8.07244L12 14.8L18.595 8.07244C18.8872 7.77438 19.3444 7.74728 19.6666 7.99115L19.7589 8.07244C20.0511 8.3705 20.0777 8.83691 19.8386 9.16558L19.7589 9.25974L12.582 16.5806C12.2898 16.8787 11.8325 16.9058 11.5103 16.6619L11.418 16.5806L4.24106 9.25974C3.91965 8.93188 3.91965 8.4003 4.24106 8.07244Z" fill="#200E32"/>
                      </svg>
                    </div>
                    <div v-else>
                      <svg width="19" height="20" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M19.7589 17.1232C19.4667 17.4213 19.0095 17.4484 18.6873 17.2045L18.595 17.1232L12 10.3957L5.40503 17.1232C5.11283 17.4213 4.65558 17.4484 4.33337 17.2045L4.24106 17.1232C3.94886 16.8252 3.9223 16.3588 4.16137 16.0301L4.24106 15.9359L11.418 8.61504C11.7102 8.31698 12.1675 8.28988 12.4897 8.53375L12.582 8.61504L19.7589 15.9359C20.0804 16.2638 20.0804 16.7954 19.7589 17.1232Z" fill="#200E32"/>
                      </svg>
                    </div>
                  </div>
                </div>
                
  
                <div class="size-box" v-if="open_colors">
                  <div v-for="(color, index) in getProduct.colors" :key="index" class="size-option">
                    <div class="size-name">
                      <input 
                        style="appearance:checkbox;" 
                        type="radio" 
                        :id="'color' + index" 
                        :value="color" 
                        v-model="picked_color" 
                      />
                    </div>
                    <div class="color" :style="colorStyle(color.value.hex)"></div>
                    <span class="text">{{ color.name }}</span>
                  </div>
                </div>
              </div>

             </div>
             
            

             <!-- <div v-if="getProduct.colors.length > 0" class="dropdown dropMenu" :style="{ background: getProduct.colors[0].value.hex}" :class="{ 'blue-border': isBlueBorder, 'gray-border': !isBlueBorder }" style="size: 30px; padding: 0;
                                                                                
              size: 30px;
              border-radius: 5px;
              " @click="toggleBlueBorder">
              </div> -->
              <br>

              <div class="add-buttons" v-if="settingsLoaded && settings && !settings.object.catalogMode">
                <div class="add-nums">
                  <button class="decrease" @click="decrease(getProduct._id)">-</button>
                  <span v-if="getProductFromStore" class="count currentItemBasketQuantity">
   
                    {{getProductFromStore.quantity}}
   
                  </span>
                  <span v-else class="count currentItemBasketQuantity">0</span>
                  <button class="increase" @click="addToCart">+</button>
                </div>
  
                <div class="add-cart" v-if="getProduct.quantity>0">
                  <button class="catalog-btn" @click="addToCart()"><a>Add to card +</a></button>
                </div>
                <div v-else-if="getProduct.quantity=-1">
                  <button class="catalog-btn" @click="addToCart()"><a>Add to card +</a></button>
                </div>
                <div v-else>
                  <span class="catalog-btn" style="background: #931f1f;">Out of stock</span>
                </div>
              </div>

              


           </div>
         </div>
       </div>
     </div>
   </div>
 </div>

  <div class="parent-modal">
    <div class="modal myModal fade" id="selectSizeModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content category-content">
          <div class="modal-header category-header align-items-center">
            <h3 class="modal-title">Please select size</h3>
            <button type="button" data-dismiss="modal" aria-label="Close" class="close">
              <span aria-hidden="true">
                <img src="../../../assets/icons/x.svg" alt="">
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>



</template>

<script>
import Spinner from "../../../components/Spinner";
import $ from 'jquery';
import Swal from "sweetalert2";
export default {
  name: "CatalogDetail",
  components:{
    Spinner
  },
  data(){
    return{
      isBlueBorder: false,
      picked_size: null,
      picked_color: null,
      checked: '',

      selectedSize:{
        price:"",
      },
      today:new Date(),
      getProduct: {
        imgArray:[],
      },

      open_sizes: false,
      open_colors: false,
      settings: null,
      settingsLoaded: false,
    }
  },
  watch: {
    picked_size(newSize) {
      if (newSize) {
        this.selectedSize = newSize;
        console.log(this.selectedSize);
        this.matchSizeQuantity();
      } else {
        // Очистить selectedSize, если ничего не выбрано
        this.selectedSize = {
          price: "",
        };
      }
    }
  },
  computed:{
    catalog_settings(){
      return this.$store.getters['Catalog/getCatalog_settings'];
    },
    currentCompanyCatalog() {
      return this.$route.params.bekon;
    },
    userDiscountStatus(){
      return this.$store.getters['Client/getUserDiscountStatus'];
    },
    getProductFromStore(){
      this.matchSizeQuantity();
      return this.$store.getters['Orders/getProduct'](this.getProduct._id)[0];
    },
    shoppingCart(){
      return this.$store.state.Orders.shoppingCart;
    },
    server(){
      return this.$server;
    },
    company_url_basket(){
      return this.$store.getters['Orders/getCompany_url_basket'];
    },
  },
  methods: {
    colorStyle(hex) {
      const isLight = this.isLightColor(hex);
      return {
        background: hex,
        border: isLight ? '1px solid #ccc' : 'none'
      };
    },

    isLightColor(hex) {
      const rgb = this.hexToRgb(hex);
      if (!rgb) return false;
      
      // Вычисляем яркость по формуле: (R * 299 + G * 587 + B * 114) / 1000
      const brightness = (rgb.r * 299 + rgb.g * 587 + rgb.b * 114) / 1000;
      
      // Проверяем, насколько цвет близок к белому
      const whiteness = (Math.min(rgb.r, rgb.g, rgb.b) / 255) * 100;
      
      // Если яркость больше 225 или белизна больше 90%, считаем цвет светлым
      return brightness > 225 || whiteness > 90;
    },

    hexToRgb(hex) {
      const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
      hex = hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b);

      const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
      } : null;
    },
    getSettings() {
      const options = {
        headers: {"X-Client-Url": this.$route.params.bekon}
      }
      console.log("catalog_Settings data", this.catalog_settings)
      let that = this;
      this.axios.get(this.url('getCatalogSettings'), options).then(function(response) {
        console.log("Settings res get", response.data);
        that.settings = response.data;
        that.settingsLoaded = true; // Устанавливаем флаг, что настройки загружены
        console.log("that.settings", that.settings)
      }).catch(function(error) {
        console.error("Ошибка при загрузке настроек:", error);
        that.settingsLoaded = true; // Устанавливаем флаг даже в случае ошибки
      });
    },
    toggleBlueBorder() {
      console.log("before toggleBlueBorder", this.isBlueBorder)
      this.isBlueBorder = !this.isBlueBorder;
      console.log("after toggleBlueBorder", this.isBlueBorder)
    },
    selectSize(size){
      // let index = this.getProduct.sizes.findIndex(x => x.size === size.target.value);
      let index = this.getProduct.sizes.findIndex(x => x.size === size);
      if(index !== -1){
        console.log(this.getProduct.sizes[index])
        this.selectedSize = this.getProduct.sizes[index];
        this.matchSizeQuantity();
      }
    },
    matchSizeQuantity(){
      let allProds = this.$store.getters['Orders/getProduct'](this.getProduct._id);
      let size = this.selectedSize;
      let index = allProds.findIndex(x => x.size._id==size._id);
      if(index !== -1){
        $('.currentItemBasketQuantity').text(allProds[index].quantity);
      }else{
        $('.currentItemBasketQuantity').text("0");
      }
    },
    increase(id,stock_quant,basket_quant){
      if(stock_quant>basket_quant || stock_quant === -1){
        this.$store.dispatch('Orders/increaseQuantity', id);
        this.$emit('checkPromocode_child',this.basket_promocode);
      }else{
        this.$warningAlert('Not enough stock');
      }
    },
    decrease(id){
      this.$store.dispatch('Orders/decreaseQuantity', id);
      this.$emit('checkPromocode_child',this.basket_promocode);
    },
    checkDates(start,end){
      let itsPromo = false;
      let startDate = new Date(start);
      let endDate = new Date(end);
      if(startDate<=this.today && endDate>=this.today){
        itsPromo = true;
      }
      return itsPromo;
    },
    addToCart(){
      if(this.selectedSize._id === undefined && this.getProduct.hasMultipleTypes){
        // $('#selectSizeModal').modal('show');
        Swal.fire({
          showConfirmButton: false,
          html: 'Select size',
          showCloseButton: true,
          showCancelButton: false,
          confirmButtonText: 'Remove',
          buttonsStyling:false,
          customClass:{
            popup: 'sweet-delete',
            confirmButton: 'confirm-btn',
            cancelButton:'cancel-btn',
            actions:'btn-group',
            content:'content-sweet',
            closeButton:'close-btn'
          },
          showClass: {
            popup: 'animate__animated animate__slideInDown'
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp'
          }
        })
        return;
      }

      if(this.getProduct.colors.length > 0 && !this.picked_color){
        Swal.fire({
          showConfirmButton: false,
          html: 'Выберите цвет',
          showCloseButton: true,
          showCancelButton: false,
          confirmButtonText: 'Удалить',
          buttonsStyling:false,
          customClass:{
            popup: 'sweet-delete',
            confirmButton: 'confirm-btn',
            cancelButton:'cancel-btn',
            actions:'btn-group',
            content:'content-sweet',
            closeButton:'close-btn'
          },
          showClass: {
            popup: 'animate__animated animate__slideInDown'
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp'
          }
        })
        return;
      }
      //check if its the same company
      console.log(this.company_url_basket,this.$route.params.bekon,"STORAGE CHECK");
      if(this.company_url_basket !==this.$route.params.bekon){
        //clear local storage
        console.log('clear');
        this.$store.dispatch("Orders/clearAll");
      }
      let that = this;
      let cart_object = {
        size:this.selectedSize,
        color: this.picked_color,
        client_status_discount:this.userDiscountStatus.discount_percentage || 0,
        product:{},
        isDiscounted:false,
        quantity:1,
        promocode:'',
        discount_percent:0,
        discount_percent_sum:0,
        discount_sum:0,
        current_price:0,
      }
      cart_object.product = this.getProduct;
      let current_price = 0;
      if(cart_object.size._id !== undefined && cart_object.product.hasMultipleTypes){
        //promo price check
        let sizeIndex = cart_object.product.sizes.findIndex(x => x._id === cart_object.size._id)
        let sizeObject = cart_object.product.sizes[sizeIndex];
        if(sizeIndex !== -1) {
          current_price = parseFloat(sizeObject.price) || 0;
          let old_price = parseFloat(sizeObject.price) || 0;

          if(this.checkDates(cart_object.product.promoStart,cart_object.product.promoEnd)){
            current_price = this.getProduct.promoPrice || 0;
            let discount_sum = old_price - current_price;
            cart_object.discount_sum = discount_sum>0? discount_sum:0;
            cart_object.isDiscounted = true;
          }else if(cart_object.client_status_discount>0){
            let disc = (current_price*(cart_object.client_status_discount/100)).toFixed(2);
            current_price = (current_price-disc).toFixed(2);
            cart_object.discount_percent_sum =disc;
          }
        }

        //
      }else{
        //promo price check
        current_price = this.getProduct.price || 0;
        let old_price = this.getProduct.price || 0;
        if(this.checkDates(cart_object.product.promoStart,cart_object.product.promoEnd)){
          current_price = this.getProduct.promoPrice || 0;
          let discount_sum = old_price - current_price;
          cart_object.discount_sum = discount_sum>0? discount_sum:0;
          cart_object.isDiscounted = true;
        }else if(cart_object.client_status_discount>0){
          let disc = (current_price*(cart_object.client_status_discount/100)).toFixed(2);
          current_price = (current_price-disc).toFixed(2);
          cart_object.discount_percent_sum =disc;
        }
        //
      }

      cart_object.current_price = current_price;
      this.$store.dispatch('Orders/addToCart', cart_object);
      this.$store.dispatch('Orders/setCompany_url_basket', that.$route.params.bekon);
      //update/set storage version
      let version = new Date();
      this.$store.dispatch("Orders/setVersion",version);
    },
    slide() {
      $('.product-img').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        fade: true,
        asNavFor: '.multiple-items'
      });
      $('.multiple-items').slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        asNavFor: '.product-img',
        dots: true,
        centerMode: true,
        focusOnSelect: true,
        responsive: [
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
              infinite: true,
              dots: true
            }
          },
        ]
      });
    },
    async  getOneProduct(){
      const options = {
        headers: {"x-client-url": this.currentCompanyCatalog}
      }
      await this.axios.get(this.url('getProductWeb',this.$route.params.id),options)
          .then((response) => {
            console.log(response);
            this.getProduct = response.data.object;

          })
      this.slide()
    },
  },
  mounted(){
    this.getSettings();
    this.getOneProduct();
  }
}
</script>

<style scoped>

.size-box .color {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin-right: 5px;
  margin-left: 5px;
  box-sizing: border-box;
}

.size-main {
  width: 100%;
  margin-right: 10px;
  position: relative;
}

.container-box {
  display: flex;
  flex-direction:row;
  justify-content: space-between;
}

.size-box {
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
  max-width: 100%;
  border-radius: 5px;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 10;
  background-color: #FFF;
  width: 100%;
}

.size-option .size-price {
  margin-right: 5px;
  border: none;
  text-align: center; /* Центрируем текст в инпуте */
  padding: 2px;
  margin-left: auto; /* Сдвигаем input в правый край */
}

.size-option .size-name {
  padding-left: 10px;
  font-size: 14.5px;
  margin: 0;
  flex-grow: 0; /* Оставляем текст в его обычном размере */
}

.size-option .size-name span {
  padding-left: 10px;
}

.size-option {
  border-radius: 5px;
  height: 35px;
  display: flex;
  align-items: center;
  max-width: 100%;
  background-color: #FFF; /* Серый цвет фона */
  
}

.size {
  border-radius: 5px;
  height: 37px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  width: 100%;
  background-color: #F8F9FB; /* Серый цвет фона */
}

.size .text {
  padding-left: 10px;
  font-size: 14.5px;
  margin: 0;
  flex-grow: 0; /* Оставляем текст в его обычном размере */
}

.size .arrow {
  margin-right: 5px;
  width: 25px; /* Фиксированная ширина инпута */
  border: none;
  text-align: center; /* Центрируем текст в инпуте */
  padding: 2px;
  margin-left: auto; /* Сдвигаем input в правый край */
}

.add-buttons {
  border-radius: 5px;
  height: 37px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  width: 100%;
}

.add-buttons .add-nums {
  margin-right: 20px;
  margin-bottom: -25px;
  flex-grow: 0; /* Оставляем текст в его обычном размере */
}

.add-buttons .add-cart {
  margin-left: 25px;
  border: none;
  text-align: center; /* Центрируем текст в инпуте */
}

.blue-border {
  border: 3px solid blue;
}

.gray-border {
  border: 3px solid gray;
}

.product-img{
  height: 440px;
  margin-bottom: 40px;
}

.selectedSizeClass{
  color:#616cf5!important;
  background: #616cf54f!important;
  border:2px solid #616cf5!important;
}
.sizeItemClass:hover{
  background: #f061f56e!important;
}
.sizeItemClass{
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background: white;
  border:2px solid #606877;
  color:#606877;
  border-radius: 5px;
  margin-right:5px;
  margin-bottom:5px;
}

.lineThrough{
  text-decoration: line-through;
  font-size: 20px!important;
  font-weight: normal !important;

}
.product-img img{
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 5px;
}
.product-name{
  font-weight: 600;
  font-size: 24px;
}
.product-code{
  color: #B0B0B0;
  font-size: 16px;
  margin: 10px 0;
}
.product-price{
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 10px;
}
.product-text{
  color: #999999;
  margin-bottom: 20px;
}
@media(min-width:1200px){
  .client-container{
    width: calc(100vw - 240px);
  }
}
@media(max-width:992px){
  .product-img{
    height: 400px;
  }
  .multiple-items{
    margin-bottom: 0;
  }
}
@media(max-width:481px) {
  .product-img {
    width: 100%;
    height: 100%;
    margin-bottom: 0
  }


  .detail-right{
    margin-bottom: 60px;
  }

  .product-img.active {
    height: 100%;
  }


}

</style>





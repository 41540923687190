<template>
  <div class="modal fade right "  id="edit-product" tabindex="-1" role="dialog" aria-labelledby="edit-product" aria-hidden="true">
    <div class="modal-dialog modal-full-height myModal-dialog mr-0 mt-0 mb-0 mr-0 h-100" style="max-width:calc(100% - 250px)" role="document" >
      <div class="modal-content myModal-content h-100">
        <div class="modal-header justify-content-start align-items-center">
          <img data-dismiss="modal" aria-label="Close" class="close mr-2" src="../../../assets/icons/xBlack.svg" alt="">
          <h3 class="modal-title">Edit</h3>
        </div>
        <div class="myModal-body">
          <div class="row">
            <div class="col-11 m-auto">
              <form  class="modal-form">
                <div class="d-flex  mb-3">
                  <div style="width:50%" class="mr-3">
                    <label class="product-label">Name</label>
                    <input :class="{errorInput: validateName}" name="name"  v-model="currentData.name" style="width:100%" class="cashback-input">
                    <div v-if="validateName" class="fill-fields">Fill in the fields</div>
                  </div>

                  <div class="quantity-category mr-3">
                    <label class="product-label">Quantity</label>
                    <input :class="{errorInput: validateQuantity}" name="quantity"  v-model="currentData.quantity" class="cashback-input">
                    <div v-if="validateQuantity" class="fill-fields" >Fill in the fields</div>
                  </div>

                  <!-- <div style="width:25%;">
                    <label class="product-label">Select category</label>

                    <select v-if="currentData.category"   name="category" v-model="currentData.category._id"  class="form-control mb-0 select-phone" >
                      <option :value="cat._id" v-for="cat in listCategory.slice(1)" :key="cat._id">{{cat.name}}</option>
                    </select>

                    <select v-else  name="category" v-model="no_category"  class="form-control mb-0 select-phone" >
                      <option :value="cat._id" v-for="cat in listCategory.slice(1)" :key="cat._id">{{cat.name}}</option>
                    </select>
                  </div> -->

                  <div style="width:25%;" ref="dropdownContainer">
                        <label class="product-label">Select category</label><br>
                        <div class="sel-block-main">
                          <div class="sel-block-main_modal" :class="{ 'show-category': isCategoryVisible }">
                            <div v-for="(category, index) in navigateDisplayParentCategories" :key="category._id">
                              <li v-if="category.name !== 'all'" class="catalog-list" :id="category.name" :ref="'menu' + index"
                                :class="{ active: selectedCategory === category._id }">
                                <p class="category-text tool-tip" data-toggle="tooltip" data-placement="top">
                                  <span class="name-category" @click="selectCategory(category._id, category.name)">{{ category.name }} </span>
                                  <span v-if="category.children && category.children.length > 0" class="arrow" :class="{ 'arrow-down': isCategoryOpen[index] }" @click="toggleCategory(index)">
                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <g clip-path="url(#clip0_89_1499)">
                                        <path d="M8.99956 9.87903L12.7121 6.16653L13.7726 7.22703L8.99956 12L4.22656 7.22703L5.28706 6.16653L8.99956 9.87903Z" fill="#D3D3D3"/>
                                      </g>
                                      <defs>
                                        <clipPath id="clip0_89_1499">
                                          <rect width="18" height="18" fill="white"/>
                                        </clipPath>
                                      </defs>
                                    </svg>
                                  </span>
                                </p>
                              </li>
                              <div v-show="isCategoryOpen[index]">
                                <div v-for="(child) in category.children" :key="child._id">
                                  <li :key="child._id" class="catalog-list catalog-list-child"
                                    :id="child.name" :ref="'menu' + index" :class="{ active: selectedCategory === child._id }">
                                    <p class="category-text tool-tip" data-toggle="tooltip" data-placement="top">
                                      <span class="name-category" @click="selectCategory(child._id, child.name)">{{ child.name }} </span>
                                      <span v-if="child.children && child.children.length > 0" class="arrow" :class="{ 'arrow-down': isChildOpen[child._id] }" @click.stop="toggleChild(index, child._id)">
                                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <g clip-path="url(#clip0_89_1499)">
                                            <path d="M8.99956 9.87903L12.7121 6.16653L13.7726 7.22703L8.99956 12L4.22656 7.22703L5.28706 6.16653L8.99956 9.87903Z" fill="#D3D3D3"/>
                                          </g>
                                          <defs>
                                            <clipPath id="clip0_89_1499">
                                              <rect width="18" height="18" fill="white"/>
                                            </clipPath>
                                          </defs>
                                        </svg>
                                      </span>
                                    </p>
                                  </li>
                                  <div v-show="isChildOpen[child._id]">
                                    <div v-for="(nestedChild) in child.children" :key="nestedChild._id">
                                      <li :key="nestedChild._id" class="catalog-list catalog-list-child catalog-list-child-nested"
                                        :id="nestedChild.name" :ref="'menu' + index" :class="{ active: selectedCategory === nestedChild._id }">
                                        <p class="category-text tool-tip" data-toggle="tooltip" data-placement="top">
                                          <span class="name-category" @click="selectCategory(nestedChild._id, nestedChild.name)">{{ nestedChild.name }}</span>
                                          <span v-if="nestedChild.children && nestedChild.children.length > 0" class="arrow" :class="{ 'arrow-down': isNestedChildOpen[nestedChild._id] }" @click.stop="toggleNestedChild(index, nestedChild._id)">
                                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <g clip-path="url(#clip0_89_1499)">
                                                <path d="M8.99956 9.87903L12.7121 6.16653L13.7726 7.22703L8.99956 12L4.22656 7.22703L5.28706 6.16653L8.99956 9.87903Z" fill="#D3D3D3"/>
                                              </g>
                                              <defs>
                                                <clipPath id="clip0_89_1499">
                                                  <rect width="18" height="18" fill="white"/>
                                                </clipPath>
                                              </defs>
                                            </svg>
                                          </span>
                                        </p>
                                      </li>
                                      <div v-show="isNestedChildOpen[nestedChild._id]">
                                        <li v-for="(fourthLevelChild) in nestedChild.children" :key="fourthLevelChild._id" class="catalog-list catalog-list-child catalog-list-child-nested catalog-list-child-fourth"
                                          :id="fourthLevelChild.name" :ref="'menu' + index" :class="{ active: selectedCategory === fourthLevelChild._id }">
                                          <p class="category-text tool-tip" data-toggle="tooltip" data-placement="top">
                                            <span class="name-category" @click="selectCategory(fourthLevelChild._id, fourthLevelChild.name)">{{ fourthLevelChild.name }}</span>
                                          </p>
                                        </li>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <input placeholder="Without category" type="text" class="cashback-input" v-model="selectedCategoryInput" readonly @click="toggleCategoryVisibility">
                        </div>
                      </div>

                </div>
                <div class="d-flex  mb-3">
                  <div style="width:35%" class="mr-3">
                    
                        <label class="product-label">{{ this.selectedColors.productCustomField1.name || 'Custom field 1' }}</label><br>
                        <input  v-model="currentData.productCustomField1" style="width:100%" class="cashback-input" :value="currentData.productCustomField1 === 'undefined' ? '' : currentData.productCustomField1">
                      </div>
                      <div style="width:35%" class="mr-3">
                        <label class="product-label">{{ this.selectedColors.productCustomField2.name || 'Custom field 2'}}</label><br>
                        <input  v-model="currentData.productCustomField2" style="width:100%" class="cashback-input" :value="currentData.productCustomField2 === 'undefined' ? '' : currentData.productCustomField2">
                      </div>
                      <div style="width:30%;" ref="dropdownContainerColor">
                        <label class="product-label">Select colors</label><br>


                        <div id="customSelect" class="custom-select" @blur="blurred">
                          <div class="selected" @click="openColorSelect">
                            Select Colors
                          </div>
                          <div class="items" :class="{ selectHide: !open }">
                            <div
                              v-for="(option, i) in productCustomFields.productCustomColors.values" 
                              :key="i" 
                              @click=" 
                                selectedColors.productCustomColors.values[i].selected = !selectedColors.productCustomColors.values[i].selected
                              "
                              class="item"
                            >
                              <div :style="{background: option.value.hex}" style="border: 2px solid gray;" class="colorBox"> </div>
                              <div class="name">
                                <label>{{ option.name }}</label>
                              </div>
                              <div class="checkBox">
                                <label class="custom-checkbox">
                                  <input type="checkbox" v-model="selectedColors.productCustomColors.values[i].selected">
                                  <span class="checkmark">
                                </span>
                                </label>
                              </div>
                            </div>
                          </div>
                          </div>
                        </div>
                </div>
                <label>Name in russian</label><br>
                <input v-model="currentData.name_ru" class="cashback-input mb-3" style="width:50%"><br>
                <label>Description</label>
                <textarea v-model="currentData.description" class="general-area p-3 mb-3" style="height:160px"   name="description"></textarea>

              <div class="d-flex mb-3">
                <label class="custom-checkbox">
                  <input v-model="currentData.hasMultipleTypes"   type="checkbox">
                  <span class="checkmark"></span>
                </label>
                <span>Has Sizes</span>
              </div>
              <div v-if="currentData.hasMultipleTypes">
                <div class="d-flex" style="justify-content: space-between;">
                  <div style="flex: 1 1 0px">Size</div>
                  <div style="flex: 1 1 0px">Quantity</div>
                  <div style="flex: 1 1 0px">Price</div>
                  <div style="flex: 1 1 0px">VendorCode</div>
                  <div style="width: 22px;"></div>
                </div>
                <div v-for="(size, index) in currentData.sizes" :key="index" class="d-flex" style="justify-content: space-between;">
                  <div style="flex: 1 1 0px">{{ size.size }}</div>
                  <div style="flex: 1 1 0px">{{ size.quantity }}</div>
                  <div style="flex: 1 1 0px">{{ size.price }} </div>
                  <div style="flex: 1 1 0px">{{ size.vendorCode }}</div>
                  <div>
                    <img @click="removeSize(index)" style="cursor: pointer;" src="../../../assets/icons/greyX.svg">
                  </div>
                </div>


                <div class="mt-1">
                  <div class="d-flex newSizeBlock">
                    <div>
                      <input placeholder="Size"  v-model="sizeObject.size" type="text" class="form-input cashback-input"  name="size_size">
                    </div>
                    <div>
                      <input placeholder="Quantity" v-model="sizeObject.quantity" type="text" class="form-input cashback-input"  name="size_quantity">
                    </div>
                    <div>
                      <input placeholder="Price" v-model="sizeObject.price" type="text"  class="form-input cashback-input"  name="size_price">
                    </div>
                    <div>
                      <input placeholder="VendorCode" v-model="sizeObject.vendorCode" type="text"  class="form-input cashback-input"  name="size_vendorCode">
                    </div>

                  </div>
                  <div class="fill-fields" v-if="addSizeError.length>0">
                    {{ addSizeError }}
                  </div>
                  <span class="save" style="cursor: pointer;width: 120px;" @click="addNewSize">Add Size</span>

                </div>
              </div>

                <div class="d-flex ">
                  <div style=" width:33.33%; margin-right:8px;">
                    <label>Price</label>
                    <input :class="{errorInput: validatePrice}" name="price" v-model="currentData.price" class="form-input cashback-input" placeholder="Price"  >
                    <div v-if="validatePrice" class="fill-fields">Fill in the fields</div>
                  </div>

                  <div style="width:33.33%;">
                    <label>Vendor Code</label>
                    <input name="vendorCode" v-model="currentData.vendorCode"  class="form-input cashback-input mb-4" placeholder="Vendor code"  >
                  </div>
                </div>



                <div class="d-flex mb-3 mt-3">
                  <label class="custom-checkbox">
                    <input v-model="showPrice" @change="checkDiscount"  id="edit-show-price" type="checkbox">
                    <span class="checkmark"></span>
                  </label>
                  <span>Discount</span>
                </div>
                <div class="d-flex ">
                  <div v-if="showPrice"  style="width:33.33%; margin-right:8px;">
                    <label>Promotional price (for all sizes)</label>
                    <input :class="{errorInput:validatePromoPrice}"  name="promoPrice" v-model="currentData.promoPrice" class="form-input cashback-input" placeholder="Price">
                    <div class="fill-fields" v-if="validatePromoPrice">Fill in the fields</div>
                  </div>
                </div>

                <label class="valid-label mt-4">Period of action</label>
                <div class=" product-calendar d-flex">
                  <div class="mr-2">
                    <label >From</label>
                    <div :class="{errorInput:validateFrom}" class="calendar d-flex align-items-center">
                      <input v-model="promoStart.formatted" id="promoStart_input" class="calendar-input">
                      <img src="../../../assets/icons/Calendar.svg">
                    </div>
                    <div class="fill-fields" v-if="validateFrom">Fill in the fields</div>
                  </div>

                  <div>
                    <label>To</label>
                    <div :class="{errorInput: validateTo}" class="calendar d-flex align-items-center">
                      <input v-model="promoEnd.formatted" id="promoEnd_input" class="calendar-input">
                      <img src="../../../assets/icons/Calendar.svg">
                    </div>
                    <div class="fill-fields" v-if="validateTo">Fill in the fields</div>
                  </div>

                </div>

                <div class="d-flex mb-3">
                  <label class="custom-checkbox"><input v-model="currentData.recommend"  type="checkbox" ><span class="checkmark"></span></label>
                  <span>Recommended</span>
                </div>

                <div class="modal-img ">
                  <label>Photos</label>
                  <p>
                    You can upload 4 more JPG or PNG photos, the minimum resolution is 400*400px, the size is<br>
                    not more than 3 MB. The first photo will be shown as the main one by default .</p>

                  <div class="d-flex">
                    <label >
                      <img src="../../../assets/img/modal-img.svg">
                      <input @change="onFileChange($event)" ref="addImage" class="d-none" multiple id="imgsArray" type="file" name="imgArray">
                    </label>
                    <div  class="d-flex">
                      <div v-for="(img, index) in imagePreview" :key="index" >
                        <div v-if="img" class="selected-images">
                          <img v-if="img ? img.startsWith('images'): ''" :src="imgSrc+'/'+img" class="show-images mr-2" />
                          <img v-else-if="img !== null"   :src="img" class="show-images mr-2" />
                          <div class="selected-overlay">
                            <img @click="removeImage(index)" class="remove-image"  src="../../../assets/icons/deleteClient.svg">
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="modal-btn d-flex">
                  <button @click.prevent="onSubmit" class="save">Save</button>
                  <button type="button" class="cancel" data-dismiss="modal" aria-label="Close" >Cancel</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
/* eslint-disable */

import $ from "jquery";

export default {
  name: "EditProduct",
  props: ['navigateDisplayParentCategories', 'listCategory','select_product','getProducts', 'productCustomFields'],
  data(){
    return {
      selectedColors: this.productCustomFields,
      selectedProduct: this.select_product,
      open: false,

      addSizeError:"",
      sizeObject:{
        size:"",
        quantity:"",
        price:"",
        vendorCode:"",
      },
      validateName:false,
      validateQuantity:false,
      validatePrice:false,
      showPrice:false,
      validateTo:false,
      validateFrom:false,
      validatePromoPrice:false,
      today:this.$moment().format("YYYY-MM-DD"),
      no_category:'',
      currentData:{
        img:'',
        imgArray:[]
      },
      imgSrc:'',
      promoStartLightpick:{},
      promoEndLightpick:{},
      promoStart: {
        obj:"",
        formatted:'',
      },
      promoEnd: {
        obj:"",
        formatted:'',
      },

      isCategoryOpen: {},
      isChildOpen: {},
      isFourthLevelChildOpen: {},
      isNestedChildOpen: {},
      selectedCategory: null,
      isCategoryVisible: false,
      selectedCategoryInput:'',
    }
  },
  computed:{
    setSelectedColors() {
      return this.productCustomFields.productCustomColors.values.map(field => {
        this.selectedColors.push({
          name: field.name,
          color: field.color,
          selected: false
        })
      });

    },
  
    mainImg(){
      if(typeof this.currentData.img === 'object'){
        return URL.createObjectURL(this.currentData.img)
      }
      return this.currentData.img
    },
    imagePreview(){
      return  this.currentData.imgArray.map((item)=>{
        if(this.currentData.imgArray.length>0){
          if(item !== null){
            if(typeof item === 'object'){
              return URL.createObjectURL(item)
            }
          }
          return item;
        }
      })
    },

  },
  methods:{
    handleClickOutside(event) {
      const dropdown = this.$refs.dropdownContainer;
      if (dropdown && !dropdown.contains(event.target)) {
        this.isCategoryVisible = false;
      }
    },
    handleClickOutsideColor(event) {
      const dropdown = this.$refs.dropdownContainerColor;
      if (dropdown && !dropdown.contains(event.target)) {
        this.open = false;
      }
    },
    toggleCategoryVisibility() {
      this.isCategoryVisible = !this.isCategoryVisible;
    },
    closeCategory(event) {
      if (!document.querySelector('.sel-block-main').contains(event.target)) {
        // Если нет, закрываем категорию
        this.isCategoryVisible = false;
      }
    },
    selectCategory(categoryId, categoryName) {
      this.currentData.category = categoryId;
      this.selectedCategoryInput = categoryName;
      this.isCategoryVisible = false;
      console.log("this.currentData", this.currentData)
    },
    toggleCategory(index) {
      console.log(index);
      this.isCategoryOpen = {...this.isCategoryOpen, [index]: !this.isCategoryOpen[index]};
    },
    toggleChild(index, childId) {
      this.isChildOpen = {...this.isChildOpen, [childId]: !this.isChildOpen[childId]};
    },

    toggleNestedChild(index, nestedChildId) {
      this.isNestedChildOpen = { 
        ...this.isNestedChildOpen, 
        [nestedChildId]: !this.isNestedChildOpen[nestedChildId] 
      };
    },

    // Функция для переключения состояния четвертого уровня вложенности
    toggleFourthLevelChild(index, fourthLevelChildId) {
      this.isFourthLevelChildOpen = { 
        ...this.isFourthLevelChildOpen, 
        [fourthLevelChildId]: !this.isFourthLevelChildOpen[fourthLevelChildId] 
      };
    },



    
    toggleSubcategories(category) {
      // Переключаем флаг для показа/скрытия подкатегорий
      if (category.children) {
        category.showChildren = !category.showChildren;
      }
    },


    openColorSelect() {
      console.log('clicked');
      this.open = !this.open;
    },
    isNumeric(str) {
      if (typeof str != "string") return false // we only process strings!
      return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
          !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
    },
    removeSize(index){
      this.currentData.sizes.splice(index,1);
    },
    addNewSize(){
      if(this.sizeObject.size.length<1){
        this.addSizeError = "Fill in size name";
        return;
      }
      if(this.sizeObject.quantity.length<1){
        // this.addSizeError = "Fill in size quantity with a numeral";
        this.sizeObject.quantity = this.currentData.quantity
      }
      if(this.sizeObject.price.length<1){
        // this.addSizeError = "Fill in size price with a numeral";
        this.sizeObject.price = this.currentData.price
      }
      this.currentData.sizes.push({ ...this.sizeObject });
      for (const property in this.sizeObject) {
        this.sizeObject[property] = "";
      }
      this.addSizeError = "";
    },

    removeImage(idx){
      this.currentData.imgArray.forEach((item,index)=>{
        if(index === idx){
          this.currentData.imgArray[index] = ""
        }
      })
      console.log(this.currentData.imgArray)
    },

    checkDiscount(){
      if(this.showPrice===false){
        this.promoStart = {
          obj:'',
          formatted:'',
        }
        this.promoEnd={
          obj:'',
          formatted:'',
        }
        this.currentData.promoPrice = 0
      }
    },
    selectDates(){
      let that=this;
      this.promoStartLightpick = new this.$lightpick({
        field: document.getElementById('promoStart_input'),
        onSelect: function(date){
          that.promoStart.obj = date;
          that.promoStart.formatted = date.format('YYYY-MM-DD');
        }
      });
      this.promoEndLightpick = new this.$lightpick({
        field: document.getElementById('promoEnd_input'),
        onSelect: function(date){
          that.promoEnd.obj = date;
          that.promoEnd.formatted = date.format('YYYY-MM-DD');
        }
      });
    },
    onFileChange(event) {
      var valid = ["image/png", "image/jpg", "image/jpeg", "image/svg"];
      const files = event.target.files;
      
      // Удаляем пустые элементы из массива
      this.currentData.imgArray = this.currentData.imgArray.filter(img => img !== "" && img !== null);
      
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        
        if (file && file.size > 3000000) {
          this.$warningAlert('Image size exceeds 3 mb');
        } else if (file && !valid.includes(file.type)) {
          this.$warningAlert('Image type not png or jpg');
        } else {
          if (this.currentData.imgArray.length < 4) {
            this.currentData.imgArray.push(file);
          } else {
            this.$warningAlert('Maximum is 4 images');
            break;
          }
        }
      }
      
      console.log(this.currentData.imgArray, 'imgArray');
      
      // Сбросить значение input, чтобы можно было выбрать те же файлы снова
      event.target.value = '';
    },

    onSubmit(){
      const updatedProduct = this.currentData;
      const form = new FormData();
      const img = updatedProduct.imgArray.find((item=>item!==''))
      if(!img){
        form.append('img', '')
      }else{
        form.append('img',img)

      }

      const newImgArray = []
      for(let item in updatedProduct.imgArray){
        if(updatedProduct.imgArray[item] !== img){
          newImgArray.push(updatedProduct.imgArray[item])
        }
      }
      for (let itm in newImgArray) {
        form.append('imgArray'+itm,  newImgArray[itm])


      }
      if(updatedProduct.name === ""){
        this.validateName = true
      }
      else{
        this.validateName = false
      }

      if(updatedProduct.quantity === ""){
        this.validateQuantity = true
      }
      else{
        this.validateQuantity = false
      }

      if(updatedProduct.price === ""){
        this.validatePrice = true
      }
      else{
        this.validatePrice = false
      }
      if(updatedProduct.category){
        console.log("updatedProduct", updatedProduct)
        form.append('category',updatedProduct.category)
      }
      else{
        form.append('category',this.no_category)
      }

      if(updatedProduct.promoPrice > updatedProduct.price){
        this.$warningAlert('Promotional price must be less than original price')
        return;
      }

      if(this.showPrice===true){
        if(updatedProduct.promoPrice === 0 || updatedProduct.promoPrice === ''){
          this.validatePromoPrice = true
        }
        else{
          this.validatePromoPrice = false
        }
        if(this.promoStart.obj === ''){
          this.validateFrom = true;

        }
        else{
          this.validateFrom  = false
        }

        if(this.promoEnd.obj === ''){
          this.validateTo = true;
          return;
        }
        else{
          this.validateTo  = false
        }
      }
      else{
        this.validateFrom = false;
        this.validateTo = false;
        this.validatePromoPrice = false;
      }

      if((new Date(this.promoEnd.formatted).getTime() < new Date(this.today).getTime())){
        this.$warningAlert('End date must greater than todays date')
        return;
      }

      if(updatedProduct.hasMultipleTypes && updatedProduct.sizes.length<0){
        this.$warningAlert('Sizes list is empty')
        return;
      }
      const colors = [];
      if (this.selectedColors.productCustomColors.required) {
        this.selectedColors.productCustomColors.values.forEach(color => {
          if(color.selected) {
            colors.push({
              name: color.name,
              value: color.value
            }
          );
          }
        })
      }
      
      const updatedSizes = this.currentData.sizes.map(size => {
        if(size.size.length<1){
          this.addSizeError = "Fill in size name";
          return false;
        }
        if(!size.price) {
          return {...size, price: updatedProduct.price }
        }
        if(!size.quantity) {
          return {...size, quantity: updatedProduct.quantity }
        }
        return size
      })
      // if size name is empty
      if(!updatedSizes) {
        return
      }

      form.append("name", updatedProduct.name)
      form.append("name_ru", updatedProduct.name_ru)
      form.append("quantity", updatedProduct.quantity)
      form.append("price", updatedProduct.price)
      form.append("description", updatedProduct.description)
      form.append("promoPrice", updatedProduct.promoPrice)
      form.append("vendorCode", updatedProduct.vendorCode)
      form.append("recommend", updatedProduct.recommend)
      form.append('promoStart',this.promoStart.obj)
      form.append('promoEnd',this.promoEnd.obj)
      form.append('sizes',JSON.stringify(updatedSizes))
      form.append('hasMultipleTypes',updatedProduct.hasMultipleTypes)
      if (colors.length > 0) {
        form.append('productCustomColors', JSON.stringify(colors))
      }
      if(updatedProduct.promoPrice > updatedProduct.price){
        this.$warningAlert("Promotional price must be < original price")
      }
      if (updatedProduct.productCustomField1) {
        form.append('productCustomField1', updatedProduct.productCustomField1);
      }
      if (updatedProduct.productCustomField2) {
        form.append('productCustomField2', updatedProduct.productCustomField2);
      }
      this.axios.put(this.url('updateProduct',updatedProduct._id),form)
        .then(()=>{
          this.getProducts()
          this.$informationAlert('Changes are saved')
          $('#edit-product').modal("hide")
          this.validateFrom = false;
          this.validateTo = false
          this.no_category = '';
          this.validateQuantity = false;
          this.validateName = false;
          this.validatePrice = false;

          this.productCustomFields.productCustomColors.values.map(field => {
          this.selectedColors.push({
            name: field.name,
            color: field.color,
            selected: false
          })
        })
      })
    },
    clearUndefinedFields() {
      if (this.currentData.productCustomField1 === 'undefined') {
        this.currentData.productCustomField1 = '';
      }
      if (this.currentData.productCustomField2 === 'undefined') {
        this.currentData.productCustomField2 = '';
      }
    },
    resetFileInput() {
      if (this.$refs.addImage) {
        this.$refs.addImage.value = '';
      }
    },
  },

  watch:{
    select_product(newCat){
      this.currentData = Object.assign({}, newCat);
      this.clearUndefinedFields();
      console.log("this.currentData", this.currentData)

      if(this.currentData.img){
        this.currentData.imgArray.unshift(this.currentData.img);
      }
      if(this.currentData.promoPrice>0){
        this.showPrice = true;
      }
      else{
        this.showPrice = false
      }
      delete this.currentData['img']
      // this.promoStart.obj = this.currentData.promoStart;
      this.promoStartLightpick.setDate(this.currentData.promoStart);
      // this.promoEnd.obj = this.currentData.promoEnd;
      this.promoEndLightpick.setDate(this.currentData.promoEnd);

      this.selectedCategoryInput =this.currentData.category.name;
      this.currentData.category = this.currentData.category._id;
      
      this.isCategoryVisible = false;

    },
    currentData:{
      handler(val) {
        console.log(val.promoPrice, !val.promoPrice || val.promoPrice<=0)
        if(!val.promoPrice || val.promoPrice<=0){
          this.unSetDates
          this.promoEnd.obj = '';
          this.promoEnd.formatted = "";
          this.promoStart.obj = '';
          this.promoStart.formatted = "";
        }
      },
      deep: true,
      immediate:true,
    },
  },
  mounted() {
    document.addEventListener('click', this.handleClickOutside);
    document.addEventListener('click', this.handleClickOutsideColor);
    this.selectDates();

    

    this.imgSrc = this.$server;

    $('#edit-product').on('hidden.bs.modal', () => {
      this.resetFileInput();
      this.imagePreview = [];
      this.currentData.imgArray = [];
    });
  },
  beforeDestroy() {
    document.removeEventListener('click', this.handleClickOutside);
    document.removeEventListener('click', this.handleClickOutsideColor);
  },
}
</script>

<style scoped>
.items.closed {
  display: none;
}
.custom-select .selected.open {
  border: 1px solid #ad8225;
  border-radius: 6px 6px 0px 0px;
}

.custom-select .items .name {
  color: #000;
  padding-left: 1em;
  cursor: pointer;
  user-select: none;
  height: 49px;
  padding-top: 14px;
}
.custom-select .items .name label {
  overflow: hidden;
  text-overflow: ellipsis; 
  max-width: 70px;
  white-space: nowrap;
}
.custom-select .items .item {
  display: flex; 
  align-items: center;
  padding: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
  width: 100%;
  border-bottom: 1px solid #D3D3D3;
  
}
.custom-select .items .colorBox{
  min-width: 30px; 
  height: 30px;
  border-radius: 5px;
}
.custom-select .items .checkBox{
  margin-left: auto;
  margin-top: 5px;
  margin-right: -20px;
}

.custom-select .items {
  width: 100%;
  font-size: 16px;
  color: #767676;
  margin-top: 10px;
  overflow-y: scroll;
  position: absolute;
  left: 0;
  right: 0;
  z-index: 1;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 5px;
  background-color: white;  
  max-height: 250px;





  
  

}
.selected {}
.custom-select {
  font-size: 16px;
  color: #767676;
  position: relative;
  width: 100%;
  text-align: left;
  outline: none;
  height: 45px;
  padding-top: 10px;
}
.selectHide {
  display: none;
}

.newSizeBlock{
  gap: 5px;
  margin-bottom: 5px;
}
.newSizeBlock div{
  flex:1 0 0;

}


.show-images{
  object-fit: contain;
}
.selected-images:hover .remove-image{
  opacity: 1;
}

.remove-image{
  opacity:1;
  transition:.3s;
}
.modal.fade:not(.in).right .modal-dialog {
  -webkit-transform: translate3d(0,0,0);
  transform: translate3d(0, 0, 0);
}
.valid-label{
  margin-bottom: 22px !important;
}
.modal-header .close{
  margin: 0;
}

.fill-fields{
  color:#E94A4A;
  margin-top: 5px;

}
.show-price.active{
  display:block;
}
.product-calendar{
  margin-bottom: 40px;
}

.calendar{
  margin-bottom: 0;
}
.modal-form input{
  width: 100%;
  font-size: 16px;
}
.modal-form label{
  margin-right: 10px;
  margin-bottom: 8px;
}
.modal-img{
  margin-bottom: 60px;
}
.modal-img p{
  margin-bottom: 15px;
  color: #828282;
  font-size: 12px;
}



.sel-block-main{
  position: relative;
}
.sel-block-main input{
  cursor: pointer;
}
.sel-block-main .sel-block-main_modal{
  position: absolute;
  top: 100%;
  background: white;
  z-index: 1;
  width: 100%;
  border-radius: 0px 0px 5px 5px;
  box-shadow: 2px 11px 35px 0px rgba(0, 0, 0, 0.1);
  display: none;
  min-width: 120px;
  padding-bottom: 20px;
  max-height: 634px;
  overflow-y: auto;
}
.sel-block-main .sel-block-main_modal.show-category{
  display: block;
}
.sel-block-main .sel-block-main_modal div .catalog-list{
  list-style: none;
  cursor: pointer;
}
.sel-block-main .sel-block-main_modal div .catalog-list:hover{
  background: rgb(248, 249, 255);
  color: black;
}
.sel-block-main .sel-block-main_modal .catalog-list + div {
    margin-left: 16px;
}
.sel-block-main .sel-block-main_modal .catalog-list .category-text {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  padding: 0 16px;
  position: relative;
}
.sel-block-main .sel-block-main_modal .catalog-list .category-text::after{
    content: '';
    position: absolute;
    display: inline-block;
    height: 1px;
    background: rgb(211, 211, 211);
    left: auto;
    bottom: 0;
    width: calc(100% - 32px);
    right: auto;
}
.sel-block-main .sel-block-main_modal .catalog-list .category-text .arrow, .sel-block-main .sel-block-main_modal .catalog-list .category-text .arrow path{
  transition: 0.3s ease;
}
.sel-block-main .sel-block-main_modal .catalog-list .category-text .arrow:hover path{
  fill: black;
}
.sel-block-main .sel-block-main_modal .catalog-list .category-text .arrow.arrow-down{
  transform: rotate(180deg);
}
.catalog-list .category-text .name-category{
  width: 100%;
  padding: 15px 0px;
  text-wrap: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
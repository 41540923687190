<template>
  <div class="edit-client">
    <div>
      <div class="edit-header d-flex justify-content-between align-items-center">
        <img class="close-edit" src="../../assets/icons/xBlack.svg" @click="$router.go(-1)">
        <div class="edit-header-left d-flex align-items-center">
          <div class="d-flex align-items-center edit-header-item">
            <img v-if="client.avatar" class="edit-img" :src="imgSrc+'/'+client.avatar">
            <img v-else class="edit-img" src="../../assets/icons/editUserAvatar.svg">
            <div>
              <h3 class="edit-name">{{client.name !== undefined ? client.name : ''}}</h3>
              <div  class="edit-category">Category: <span>{{client.category && client.category!== null ? client.category.name : 'No category'}}</span></div>
              <span class="edit-user"  data-toggle="modal"  data-target="#edit-client" >Edit user</span>
            </div>
          </div>
        </div>
  
        <div class="edit-header-right d-flex align-items-center">
          <div class="selects d-flex" style="margin-right: 26px;">
            <!-- Кешбэк д��опдаун (слева) -->
            <div ref="cashbackDropdownContainer" style="margin-right: 10px;">
              <div class="sel-block-main">
                <div class="sel-block-main_modal" :class="{ 'show-category': isCashbackCategoryVisible }">
                  <div>
                    <li class="catalog-list" id="without-cashback">
                      <p class="category-text tool-tip" data-toggle="tooltip" data-placement="top">
                        <span class="name-category" @click="selectCashbackCategory(null, 'Without cashback', 0, 0)">
                          <span class="category-nm">Without cashback</span>
                          <span class="category-pr">0%</span>
                        </span>
                      </p>
                    </li>
                  </div>
                  <div v-for="(category, index) in cashbackList" :key="category._id">
                    <li v-if="category.name !== 'all'" class="catalog-list" :id="category.name" :ref="'cashbackMenu' + index"
                      :class="{ active: selectedCashbackCategory === category._id }">
                      <p class="category-text tool-tip" data-toggle="tooltip" data-placement="top">
                        <span class="name-category" @click="selectCashbackCategory(category._id, category.name, category.discount_percentage, category.min_sum_of_purchases)">
                          <span class="category-nm">{{ category.name }}</span>
                          <span class="category-pr">{{ category.discount_percentage }}%</span>
                        </span>
                        
                        <span v-if="category.children && category.children.length > 0" class="arrow" :class="{ 'arrow-down': isCashbackCategoryOpen[index] }" @click="toggleCashbackCategory(index)">
                          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" style="margin-left: 2px;" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_89_1499)">
                            <path d="M8.99956 9.87903L12.7121 6.16653L13.7726 7.22703L8.99956 12L4.22656 7.22703L5.28706 6.16653L8.99956 9.87903Z" fill="#D3D3D3"/>
                            </g>
                            <defs>
                            <clipPath id="clip0_89_1499">
                            <rect width="18" height="18" fill="white"/>
                            </clipPath>
                            </defs>
                          </svg>
                        </span>
                      </p>
                    </li>
                  </div>
                </div>
                <input placeholder="Without cashback" type="text" class="selectcat-input" v-model="selectedCashbackCategoryInput" readonly @click="toggleCashbackCategoryVisibility">
                <svg width="22" height="22" viewBox="0 0 18 18" fill="none" style="position: absolute; margin-left: -30px; margin-top: 7px" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_89_1499)">
                  <path d="M8.99956 9.87903L12.7121 6.16653L13.7726 7.22703L8.99956 12L4.22656 7.22703L5.28706 6.16653L8.99956 9.87903Z" fill="#D3D3D3"/>
                  </g>
                  <defs>
                  <clipPath id="clip0_89_1499">
                  <rect width="25" height="25" fill="white"/>
                  </clipPath>
                  </defs>
                </svg>
              </div>
            </div>

            <!-- Скидка дропдаун (справа) -->
            <div ref="dropdownContainer">
              <div class="sel-block-main">
                <div class="sel-block-main_modal" :class="{ 'show-category': isCategoryVisible }">
                  <div>
                    <li class="catalog-list" id="without-discount">
                      <p class="category-text tool-tip" data-toggle="tooltip" data-placement="top">
                        <span class="name-category" @click="selectCategory(null, 'Without discount', 0, 0)">
                          <span class="category-nm">Without discount</span>
                          <span class="category-pr">0%</span>
                        </span>
                      </p>
                    </li>
                  </div>
                  <div v-for="(category, index) in discountList" :key="category._id">
                    <li v-if="category.name !== 'all'" class="catalog-list" :id="category.name" :ref="'menu' + index"
                      :class="{ active: selectedCategory === category._id }">
                      <p class="category-text tool-tip" data-toggle="tooltip" data-placement="top">
                        <span class="name-category" @click="selectCategory(category._id, category.name, category.discount_percentage, category.min_sum_of_purchases)">
                          <span class="category-nm">{{ category.name }}</span>
                          <span class="category-pr">{{ category.discount_percentage }}%</span>
                        </span>
                        
                        <span v-if="category.children && category.children.length > 0" class="arrow" :class="{ 'arrow-down': isCategoryOpen[index] }"  @click="toggleCategory(index)">
                          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" style="margin-left: 2px;" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_89_1499)">
                            <path d="M8.99956 9.87903L12.7121 6.16653L13.7726 7.22703L8.99956 12L4.22656 7.22703L5.28706 6.16653L8.99956 9.87903Z" fill="#D3D3D3"/>
                            </g>
                            <defs>
                            <clipPath id="clip0_89_1499">
                            <rect width="18" height="18" fill="white"/>
                            </clipPath>
                            </defs>
                          </svg>
                        </span>
                      </p>
                    </li>
                  </div>
                </div>
                <input placeholder="Without discount" type="text" class="selectcat-input" v-model="selectedCategoryInput" readonly @click="toggleCategoryVisibility">
                <svg width="22" height="22" viewBox="0 0 18 18" fill="none" style="position: absolute; margin-left: -30px; margin-top: 7px" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_89_1499)">
                  <path d="M8.99956 9.87903L12.7121 6.16653L13.7726 7.22703L8.99956 12L4.22656 7.22703L5.28706 6.16653L8.99956 9.87903Z" fill="#D3D3D3"/>
                  </g>
                  <defs>
                  <clipPath id="clip0_89_1499">
                  <rect width="25" height="25" fill="white"/>
                  </clipPath>
                  </defs>
                </svg>
              </div>
            </div>
          </div>
          <div style="margin-right: 26px;" class="d-flex align-items-center" data-toggle="modal" data-target="#send-push"><img src="../../assets/icons/roundPlus.svg"><span>Send push</span></div>
          <div style="margin-right: 26px;" class="d-flex align-items-center" data-toggle="modal" data-target="#add-points"><img src="../../assets/icons/roundPlus.svg"><span>Add points</span></div>
          <div style="margin-right: 26px;" class="d-flex align-items-center" data-toggle="modal" data-target="#remove-points"><img src="../../assets/icons/roundMinus.svg"><span>Remove points</span></div>
        </div>
      </div>
  
      <div class="line"></div>
  
      <OrderDetail
          :orderDetail="orderDetail"
      />
      <AddPoints
          :client="client"
          :getClient="getClient"
      />
      <RemovePoints
          :client="client"
          :getClient="getClient"
      />
      <SendPush
          :client="client"
      />
      <EditClient v-bind:select_client="client"
        :getClient="getClient"
      />
  <!-------------------------End of Header --------------------------------------- - -->
  
      <div class="row client-number-row">
        <div class="col-lg-2 client-number-box">
          <span class="client-number-label">Points</span>
          <h5 class="client-number" style="color:#616cf5">{{ Math.round(client.points) }}</h5>
        </div>
        <div class="col-lg-2 client-number-box">
          <span class="client-number-label">Paid by points</span>
          <h5 class="client-number">{{ Math.round(usedPoints) }}</h5>
        </div>
        <div class="col-lg-2 client-number-box">
          <span class="client-number-label">Total of paid</span>
          <h5 class="client-number">{{ Math.round(totalPaid) }}</h5>
        </div>
      </div>
  
      <div class="d-flex align-items-center nav-buttons justify-content-between">
        <ul class="nav nav-tabs">
          <li>
            <a  class="disable-underline purchase"  data-toggle="tab" href="#menu1">
              <div class="order-tab d-flex align-items-center mr-4">
                <h2 class="history-title">Purchase history</h2>
              </div>
            </a>
          </li>
          <li>
            <a class="disable-underline bonus" data-toggle="tab" href="#bonuses">
              <div data-toggle="tab" class="order-tab d-flex align-items-center">
                <h2 class="history-title">Bonuses</h2>
              </div>
            </a>
          </li>
        </ul>
  
        <button @click="clickOnDate" class="app-buttons-item"><img src="../../assets/icons/yesterday.svg"><input :value="between_value"  class="date-pick" id="datepicker" readonly></button>
  
      </div>
  
      <div class="tab-content">
        <div id="menu1" class="tab-pane fade">
          <div class="d-flex main-content-header">
            <div class="table-head" style="width: 45%;">Order name</div>
            <div class="table-head table-link" @click="sortByDate(purchaseHistory)" style="width: 25%;">Date<img class="date-pol" style="margin-left:10px" src="../../assets/icons/polygon.svg"></div>
            <div class="table-head " style="width: 25%;" >Total </div>
            <div class="table-head " style="width: 5%;" ></div>
          </div>
  
          <div class="table-content">
            <div v-if="purchaseHistory.length ===0" class="text-center mt-5">
                <img src="../../assets/icons/emptyOrder.svg" class="mb-3" style="width:60px; height:60px">
                <p class="client-paragraph">Information about your orders will be stored here</p>
              </div>
            <div v-else v-for="order in filteredPurchaseHistory"  :key="order._id" class="table-item d-flex align-items-center">
                <div style="width:45%" class="d-flex align-items-center">
                  {{order.code}}
                </div>
                <div style="width:25%"> {{order.createdAt ? order.createdAt.slice(0,10) : ''}}</div>
                <div style="width:25%">{{order.totalPrice}} {{settings.currency}}</div>
                <div style="width:5%;" class="detail"  data-toggle="modal" data-target="#order-detail" @click="orderDetail = order">details</div>
            </div>
          </div>
        </div>
        <div id="bonuses" class="tab-pane fade">
          <div class="d-flex main-content-header">
            <div class="table-head" style="width: 40%;">Name</div>
            <div class="table-head table-link" @click="sortByDate(historyBonus)" style="width: 20%;">Date<img class="date-pol" style="margin-left:10px" src="../../assets/icons/polygon.svg"></div>
            <div class="table-head " style="width: 30%;" >Notes </div>
            <div class="table-head " style="width: 10%;" >Quantity </div>
  
          </div>
  
          <div class="table-content">
            <div v-for="bonus in filteredBonusHistory" :key="bonus._id" class="table-item d-flex align-items-center">
              <div style="width:40%" class="d-flex align-items-center">
                {{bonus.source}}
              </div>
              <div style="width:20%">{{bonus.createdAt?bonus.createdAt.slice(0,10):''}}</div>
              <div style="width:30%" class="pr-3">{{bonus.comments?bonus.comments:'--'}}</div>
              <div v-if="bonus.type === 'received'" class="received" style="width:10%">+{{bonus.points}} P</div>
              <div v-else-if="bonus.type==='used'" class="used" style="width:10%">-{{bonus.points}} P</div>
              <div v-else style="width:10%" class="removed">{{bonus.points}} P</div>
  
            </div>
  
          </div>
        </div>
      </div>
  
  
  
    </div>
  </div>
  </template>
  
  <script>
  import $ from "jquery";
  import AddPoints from "@/modals/client/AddPoints";
  import RemovePoints from "@/modals/client/RemovePoints";
  import SendPush from "@/modals/client/SendPush";
  import EditClient from "../../modals/client/EditClient";
  import OrderDetail from "../../modals/client/OrderDetail";
  export default {
    name: "EditClientPage",
    components: {
      AddPoints,
      RemovePoints,
      SendPush,
      EditClient,
      OrderDetail
  
    },
    data() {
      return {
        client: {},
        imgSrc: '',
        purchaseHistory:[],
        orderDetail: '',
        sorting: false,
        discountList: [],
        historyBonus:  [],
        between_value:'',
        filtered:'',
  
        settings: {},
  
        currentData: {},
        isCategoryOpen: {},
        isChildOpen: {},
        selectedCategory: null,
        isCategoryVisible: false,
        selectedCategoryInput:'',
        cashbackList: [],
        isCashbackCategoryVisible: false,
        isCashbackCategoryOpen: {},
        selectedCashbackCategory: null,
        selectedCashbackCategoryInput: '',
      }
    },
    computed:{
  
      usedPoints() {
        return this.historyBonus.filter((item)=>item.type ==='used').reduce((acc,el)=>acc+parseInt(el.points),0);
      },
      totalPaid(){
        return this.purchaseHistory.reduce((acc,item)=>acc+item.totalPrice, 0)
      },
      filteredPurchaseHistory(){
        return this.purchaseHistory.filter((order)=>{
          return (new Date(order.createdAt) >= new Date(this.filtered.slice(0,10)) &&
              new Date(order.createdAt) <= new Date(this.filtered.slice(14,24)))
  
        })
      },
      filteredBonusHistory(){
         return this.historyBonus.filter((order)=>{
          return (new Date(order.createdAt).getTime() >= new Date(this.filtered.slice(0,10)).getTime() &&
              new Date(order.createdAt).getTime() <= new Date(this.filtered.slice(14,24)).getTime()  )
        })
      }
    },
  
    methods: {
      getSettings() {
        let that = this;
        this.axios.get(this.url('getSettings'))
          .then((response) => {
            that.settings = response.data.object;
          }).catch(function (error) {
            if (error.response) {
              console.log('getsettings err', error.response)
            }
          })
      },
      handleClickOutside(event) {
        const dropdown = this.$refs.dropdownContainer;
        const cashbackDropdown = this.$refs.cashbackDropdownContainer;
        if (dropdown && !dropdown.contains(event.target) && cashbackDropdown && !cashbackDropdown.contains(event.target)) {
          this.isCategoryVisible = false;
          this.isCashbackCategoryVisible = false;
        }
      },
      toggleCategoryVisibility() {
        console.log('toggleCategoryVisibility');
        this.isCategoryVisible = !this.isCategoryVisible;
      },
      closeCategory(event) {
        if (!document.querySelector('.sel-block-main').contains(event.target)) {
          // Если нет, закрываем категорию
          this.isCategoryVisible = false;
        }
      },
      selectCategory(categoryId, categoryName, discount_percentage, min_sum_of_purchases) {
        console.log('selectCategory', categoryId, categoryName, discount_percentage, min_sum_of_purchases);
        this.currentData.category = categoryId;
        this.selectedCategoryInput = categoryId ? categoryName + ` (${discount_percentage}%)` : 'Without discount';
  
        const newClient = this.client;
        if(!newClient.birthDate){
            newClient.birthDate = this.$moment()
        }
        const val = discount_percentage;
        console.log("doc_dis", val)
        newClient.discount = val;
        newClient.discount_id = categoryId;
        newClient.discount_input = this.selectedCategoryInput;
        console.log("newClient", newClient)
        this.axios.put(this.url('updateClient', this.client._id),newClient)
        .then(()=>{
          this.$successAlert("Discount has been changed")
        })
        .catch(()=>{
          this.$warningAlert('Something went wrong please try again')
        })
  
        this.isCategoryVisible = false;
  
      },
      toggleCategory(index) {
        console.log(index);
        this.isCategoryOpen = {...this.isCategoryOpen, [index]: !this.isCategoryOpen[index]};
      },
      toggleChild(index, childId) {
        this.isChildOpen = {...this.isChildOpen, [childId]: !this.isChildOpen[childId]};
      },
      toggleSubcategories(category) {
        // Переключаем флаг для показа/скрытия подкатегорий
        if (category.children) {
          category.showChildren = !category.showChildren;
        }
      },
  
  
  
      changeDiscount(id){
        const newClient = this.client;
        if(!newClient.birthDate){
            newClient.birthDate = this.$moment()
        }
        const val = +document.getElementById('change-discount').value;
        console.log("doc_dis", +document.getElementById('change-discount').value)
        newClient.discount = val;
        console.log("newClient", newClient)
        this.axios.put(this.url('updateClient',id),newClient)
        .then(()=>{
          this.$successAlert("Discount has been changed")
        })
        .catch(()=>{
          this.$warningAlert('Something went wrong please try again')
        })
      },
      sortByDate(array) {
        if (array.length === 0) {
          return null;
        } else {
          array.sort((a, b) => {
            return this.sorting ? new Date(a.createdAt) - new Date(b.createdAt) : new Date(b.createdAt) - new Date(a.createdAt)
          })
          this.sorting = !this.sorting;
          $('.date-pol').toggleClass('active')
          $('.total-pol').removeClass('active')
        }
      },
      getClient() {
        this.axios.get(this.url('getClient', this.$route.params.id))
            .then((res) => {
              this.client = res.data.object;
              this.historyBonus = res.data.history;
              this.purchaseHistory = res.data.orders;
  
              this.selectedCategoryInput = this.client.discount_input ? this.client.discount_input : 'Without discount';
              this.selectedCashbackCategoryInput = this.client.cashback_input ? this.client.cashback_input : 'Without cashback';
  
              console.log(res, 'FFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFf')
            })
      },
      getDiscounts() {
        this.axios.get(this.url('getDiscounts'))
            .then((res) => {
              this.discountList = res.data.discounts;
            })
      },
      getCashbacks() {
        this.axios.get(this.url('getNewCashbacks'))
          .then((res) => {
            this.cashbackList = res.data.cashbacks;
            console.log('Полученные кешбэки:', this.cashbackList);
          })
          .catch((error) => {
            console.error('Ошибка при получении кешбэков:', error);
            this.$warningAlert('Не удалось загрузить список кешбэков');
          });
      },
      toggleCashbackCategoryVisibility() {
        this.isCashbackCategoryVisible = !this.isCashbackCategoryVisible;
      },
      selectCashbackCategory(categoryId, categoryName, cashback_percentage, min_sum_of_purchases) {
        console.log('selectCashbackCategory', categoryId, categoryName, cashback_percentage, min_sum_of_purchases);
        this.selectedCashbackCategory = categoryId;
        this.selectedCashbackCategoryInput = categoryId ? categoryName + ` (${cashback_percentage}%)` : 'Without cashback';
        
        const newClient = this.client;
        if(!newClient.birthDate){
            newClient.birthDate = this.$moment()
        }
        newClient.cashback = cashback_percentage;
        newClient.cashback_id = categoryId;
        newClient.cashback_input = this.selectedCashbackCategoryInput;
        
        console.log("newClient", newClient);
        this.axios.put(this.url('updateClient', this.client._id), newClient)
        .then(() => {
          this.$successAlert("Кешбэк был изменен")
        })
        .catch(() => {
          this.$warningAlert('Что-то пошло не так, попробуйте еще раз')
        })
        
        this.isCashbackCategoryVisible = false;
      },
      toggleCashbackCategory(index) {
        this.isCashbackCategoryOpen = {...this.isCashbackCategoryOpen, [index]: !this.isCashbackCategoryOpen[index]};
      },
      filteredBetweenDate(){
        this.filtered = this.between_value
      },
      clickOnDate(){
        $('.date-pick').click()
      },
  
    },
    mounted() {
      this.getSettings();

      document.addEventListener('click', this.handleClickOutside);
      // Извлекаем clientId из URL
      const url = window.location.href;
      const clientId = url.split('/').pop(); // Предполагается, что ID клиента в конце URL
  
      const reloadFlagKey = `reloadFlag_${clientId}`;
      const clientLoadedKey = `clientLoaded_${clientId}`;
  
      // Проверяем флаг перезагрузки
      if (!localStorage.getItem(clientLoadedKey)) {
        // Если страницы для данного клиента еще не было загружено, перезагружаем страницу
        localStorage.setItem(clientLoadedKey, 'true');
        localStorage.setItem(reloadFlagKey, 'true');
        location.reload();
      } else {
        // Устанавливаем флаг, что клиент уже был загружен
        localStorage.setItem(clientLoadedKey, 'false');
      }
      
  
    
  
      this.getClient();
      this.getDiscounts();
      this.getCashbacks();
      $('.nav-tabs a:first').click();
      this.imgSrc = this.$server;
  
      new this.$lightpick({
        field: document.getElementById('datepicker'),
        singleDate: false,
        numberOfMonths: 2,
        numberOfColumns:2,
        autoClose:true,
        format:'YYYY-MM-DD',
        onSelect: (start,end)=>{
          var str = '';
          str += start ? start.format('YYYY-MM-DD') + ' to ' : '';
          str += end ? end.format('YYYY-MM-DD') : '...';
          this.between_value = str;
          this.filteredBetweenDate()
        }
      });
      const to_date = this.$moment().subtract(1, "days").format("YYYY-MM-DD")
      const from_date = this.$moment().format('YYYY-MM-DD')
      const tomorrow  = this.$moment().add(1,'days').format('YYYY-MM-DD');
      this.between_value = to_date + ' to ' + from_date;
      this.filtered = from_date + ' to ' + tomorrow
  
  
  
    },
    beforeUnmount() {
      document.removeEventListener('click', this.handleClickOutside);
    },
  }
  
  </script>
  
  <style scoped>
  .date-pick{
    width:182px;
    height: 20px;
    background: none;
    border:none;
    cursor:pointer;
    color:#606877;
  }
  .close-edit{
    position: absolute;
    top: 25%;
    left: -30px;
  }
  .table-content{
    height: 245px;
  }
  .edit-header{
    position: relative;
  }
  .edit-client{
    padding: 0 60px;
  }
  .used{
    color:orange;
  }
  .removed{
    color:red;
  }
  .received{
    color:green;
  }
  .detail{
    color:#616cf5;
    cursor:pointer;
  }
  .table-item{
    height: 60px;
  }
  .order-tab{
    opacity:0.5;
    border-bottom:1px solid transparent;
    padding-bottom: 6px;
  }
  .order-tab:hover{
    opacity: 1;
    transition: .3s;
    border-bottom: 1px solid #616CF5;
    padding-bottom: 6px;
  }
  .disable-underline.active .order-tab{
    opacity: 1;
    border-bottom:1px solid #616cf5;
  }
  .line{
    margin-bottom: 30px;
  }
  .client-number-row{
    margin-bottom: 60px;
  }
  .client-number{
    font-size: 20px;
  }
  .client-number-box{
    border-right: 1px solid #E3E3E3;
  }
  .client-number-box:last-child{
    border-right: none;
  }
  .client-number-label{
    color: #B0B0B0;
    font-size: 14px;
    margin-bottom: 10px;
    display: inline-block;
  }
  
  .form-control{
    background: #F8F9FB;
    border:none;
    height: 36px;
    padding-right: 30px;
  
  }
  
  .edit-user{
    color:#616CF5;
    font-size: 14px;
    cursor:pointer;
  }
  .history-title{
    font-size: 16px;
    color:#000;
  }
  .nav-buttons{
    margin-bottom: 30px;
  }
  .edit-header{
    height: 140px;
  }
  .edit-img{
    width: 80px;
    height: 80px;
    border-radius:50%;
    margin-right: 10px;
    object-fit: contain;
  }
  .edit-name{
    font-size: 20px;
    font-weight: normal;
    margin-bottom: 5px;
  }
  .edit-category{
    color:#8C94A5;
    margin-bottom: 5px;
    white-space: nowrap;
  
  }
  .edit-category span{
    color:#000;
    text-transform: capitalize;
    white-space: nowrap;
  }
  
  
  .edit-header-item{
    margin-right: 40px;
  }
  .edit-header-right{
    color:#616cf5;
    font-size: 16px;
    font-weight: normal;
    cursor:pointer;
    white-space: nowrap;
  }
  .edit-header-right img{
    margin-right: 10px;
  }
  
  
  .selectcat-input {
    max-width: max-content;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border: none;
    background: rgba(248, 249, 251, 1);
    max-height: 35px;
    width: 100%;
    border-radius: 5px;
    height: 45px;
    padding-left: 10px;
    padding-right: 30px;
  }

  .selectcat-input div{
    width: 50%;
  }

  
  .sel-block-main{
    position: relative;
  }
  .sel-block-main input{
    cursor: pointer;
  }
  .sel-block-main .sel-block-main_modal{
    position: absolute;
    top: 120%;
    background: white;
    z-index: 1;
    width: 100%;
    border-radius: 0px 0px 5px 5px;
    box-shadow: 2px 11px 35px 0px rgba(0, 0, 0, 0.1);
    display: none;
    min-width: 120px;
    padding-bottom: 20px;
    max-height: 634px;
    overflow-y: auto;
  }
  .sel-block-main .sel-block-main_modal.show-category{
    display: block;
  }
  .sel-block-main .sel-block-main_modal div .catalog-list{
    list-style: none;
    cursor: pointer;
  }
  .sel-block-main .sel-block-main_modal div .catalog-list:hover{
    background: rgb(248, 249, 255);
    color: black;
  }
  .sel-block-main .sel-block-main_modal .catalog-list + div {
      margin-left: 0px;
  }
  .sel-block-main .sel-block-main_modal .catalog-list .category-text {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    padding: 0 16px;
    position: relative;
  }
  .sel-block-main .sel-block-main_modal .catalog-list .category-text::after{
      content: '';
      position: absolute;
      display: inline-block;
      height: 1px;
      background: rgba(235, 235, 235, 1);
      left: auto;
      bottom: 0;
      width: calc(100% - 32px);
      right: auto;
  }
  .sel-block-main .sel-block-main_modal .catalog-list .category-text .arrow, .sel-block-main .sel-block-main_modal .catalog-list .category-text .arrow path{
    transition: 0.3s ease;
  }
  .sel-block-main .sel-block-main_modal .catalog-list .category-text .arrow:hover path{
    fill: black;
  }
  .sel-block-main .sel-block-main_modal .catalog-list .category-text .arrow.arrow-down{
    transform: rotate(180deg);
  }
  .catalog-list .category-text .name-category{
    width: 100%;
    padding: 15px 0px;
    text-wrap: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: flex;
    align-items: center;
  }
  .category-nm {
    color: black;
    font-size: 16px;
    margin: 0;
    flex-grow: 0; /* Оставляем текст в его обычном размере */
    max-width: max-content;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .category-pr {
    color: black;
    border: none;
    text-align: center; /* Центрируем текст в инпуте */
    margin-left: auto; /* Сдвигаем input в правый край */
  }

  .selects {
    display: flex;
    align-items: center;
  }

  .sel-block-main {
    width: 200px; /* Установите подходящю ширину */
  }

  </style>